<template>
  <div class="d-flex flex-column flex-root">
    <div class="white rounded-lg pa-5 py-md-8 px-md-10">
      <template>
        <!-- begin:: Page main title -->
        <v-row>
          <v-col cols="12" class="pa-0">
            <p
              class="display-1 font-weight-medium text-center text-md-left text-blue-grey lighten-2 mb-1 mt-3"
            >
              Asignaturas
            </p>
          </v-col>
        </v-row>
        <!-- end:: Page main title -->

        <!-- begin:: Academic subjects container -->
        <v-container class="px-0">
          <!-- begin:: Academic subjects container title-->
          <v-row class="my-1 px-3">
            <v-col cols="12" class="d-flex flex-column flex-sm-row">
              <p class="text-center headline mr-sm-3 mb-0 mt-2 mt-sm-0">
                Académicas
              </p>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <!-- end:: Academic subjects container title-->

          <!-- begin:: Academic Subjects Loader -->
          <div v-if="isLoadingSubjects" style="height: 30vh">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col class="text-subtitle-1 text-center" cols="12">
                Cargando asignaturas académicas
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  color="blue darken-1"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </div>
          <!-- end:: Academic Subjects Loader -->

          <v-row v-else gutters class="d-flex justify-start ma-0">
            <!-- begin:: Fallback screen in case there is not a single evaluation -->
            <v-col
              cols="12"
              v-if="subjects.length == 0"
              style="height: 200px"
              class="rounded-lg grey lighten-5 mx-2"
            >
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-icon color="blue darken-1" x-large> mdi-book-off </v-icon>
                </v-col>
                <v-col
                  class="text-subtitle-1 text-center font-weight-medium"
                  cols="12"
                >
                  No hay ninguna asignatura académica correspondiente a tu grado
                  o especialidad
                </v-col>
              </v-row>
            </v-col>
            <!-- end:: Fallback screen in case there is not a single evaluation -->

            <!-- begin:: Subject cards -->
            <v-col
              v-else
              cols="12"
              sm="6"
              md="4"
              xl="3"
              v-for="(item, index) in subjects"
              :key="index"
            >
              <div
                class="elevation-2 rounded-lg subject-card d-flex flex-column align-stretch justify-center"
              >
                <div
                  class="px-5 py-5 pb-0 d-flex flex-column justify-center align-start justify-md-start flex-md-row"
                >
                  <div
                    :class="`${
                      colors[item.subject_id % 12]
                    } card-icon-container lighten-4 d-flex rounded-circle mx-auto mx-md-0 pa-5 mb-3 mb-md-0
`"
                  >
                    <v-icon
                      :color="`${colors[item.subject_id % 12]}
`"
                      >mdi-human-male-board</v-icon
                    >
                  </div>
                  <!-- texto -->
                  <div class="mx-auto ml-md-5">
                    <p
                      class="ma-0 mb-0 text-h6 font-weight-bold text-center text-md-left"
                    >
                      {{ item.subject }}
                    </p>
                    <p
                      class="text-body-1 blue-grey--text text--lighten-1 ma-0 text-center text-md-left"
                    >
                      {{ item.full_name }}
                    </p>
                  </div>
                </div>
                <!-- action button -->
                <div
                  class="px-5 pb-3 pt-0 d-flex align-end justify-center justify-md-end mt-auto"
                >
                  <v-btn
                    dense
                    small
                    rounded
                    outlined
                    :color="`${colors[item.subject_id % 12]}`"
                    @click="goToSubjectEvaluations(item)"
                    class="ma-2 white--text"
                  >
                    Ver perfiles y notas
                    <v-icon right dark> mdi-arrow-right-circle </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <!-- end:: Subject cards -->
          </v-row>
        </v-container>
        <!-- end:: Academic sujects container -->

        <!-- begin:: Technical subjects container -->
        <v-container class="px-0">
          <v-row class="my-1 px-3">
            <v-col cols="12" class="d-flex flex-column flex-sm-row">
              <p class="text-center headline mr-sm-3 mb-0 mt-2 mt-sm-0">
                Módulos
              </p>
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <!-- begin:: Technical Subjects Loader -->
          <div v-if="isLoadingModules" style="height: 30vh">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col class="text-subtitle-1 text-center" cols="12">
                Cargando asignaturas módulares
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  color="blue darken-1"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </div>
          <!-- end:: Technical Subjects Loader -->

          <v-row v-else gutters class="d-flex justify-start ma-0">
            <v-col
              cols="12"
              v-if="modules.length == 0"
              style="height: 200px"
              class="rounded-lg grey lighten-5 mx-2"
            >
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-icon color="blue darken-1" x-large> mdi-book-off </v-icon>
                </v-col>
                <v-col
                  class="text-subtitle-1 text-center font-weight-medium"
                  cols="12"
                >
                  No hay ninguna asignatura módular correspondiente a tu grado o
                  especialidad
                </v-col>
              </v-row>
            </v-col>

            <!-- begin:: Modules cards -->
            <v-col
              v-else
              cols="12"
              md="6"
              xl="4"
              v-for="(item, index) in modules"
              :key="index"
            >
              <div
                class="elevation-2 rounded-lg subject-card d-flex flex-column align-stretch justify-center"
              >
                <!-- progress indicator -->
                <div class="module-state-indicator">
                  <v-tooltip
                    :color="`${moduleStateColorSetter(item.status)}`"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        class="ma-2"
                        outlined
                        :color="`${moduleStateColorSetter(item.status)}`"
                        label
                      >
                        <v-icon>
                          mdi-{{ moduleStateIconSetter(item.status) }}
                        </v-icon>
                      </v-chip>
                    </template>
                    <span>{{ moduleStateTextSetter(item.status) }}</span>
                  </v-tooltip>
                </div>

                <div
                  class="px-5 py-5 pb-0 d-flex flex-column justify-center align-start justify-md-start flex-md-row"
                >
                  <div
                    :class="`${
                      colors[item.subject_id % 12]
                    } card-icon-container lighten-4 d-flex rounded-circle mx-auto mx-md-0 pa-5 mb-3 mb-md-0
`"
                  >
                    <v-icon
                      :color="`${colors[item.subject_id % 12]}
`"
                      >mdi-cube-outline</v-icon
                    >
                  </div>
                  <!-- texto -->
                  <div class="mx-auto ml-md-5">
                    <p
                      class="ma-0 mb-0 text-h6 font-weight-bold text-center text-md-left"
                    >
                      {{ item.subject }}
                    </p>
                    <p
                      class="text-body-1 blue-grey--text text--lighten-1 ma-0 text-center text-md-left"
                    >
                      {{ item.full_name }}
                    </p>
                  </div>
                </div>
                <!-- action button -->
                <div
                  class="px-5 pb-3 pt-0 d-flex align-end justify-center justify-md-end mt-auto"
                >
                  <v-btn
                    dense
                    small
                    rounded
                    outlined
                    :color="`${colors[item.subject_id % 12]}`"
                    @click="goToSubjectEvaluations(item)"
                    class="ma-2 white--text"
                  >
                    Ver niveles de logro
                    <v-icon right dark> mdi-arrow-right-circle </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <!-- end:: Modules cards -->
          </v-row>
        </v-container>
        <!-- end:: Technical subjects container -->
      </template>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_SUBJECT } from "@/core/services/store/subject.module";
import { mapGetters } from "vuex";
import subjectAndModuleRepository from "@/repositories/subjectAndModuleRepository";

export default {
  name: "ViewSubjects_Modules",
  title: "Asignaturas | PE ITR",
  data() {
    return {
      subjects: [],
      modules: [],
      isLoadingSubjects: false,
      isLoadingModules: false,
      isConvertingCode: false,
      isFormCompleted: false,
    };
  },
  props: {
    student_id: {
      type: Number,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Notas", route: "view_subjects_modules" },
      { title: "Asignaturas", route: "view_subjects_modules" },
    ]);
    this.loadSubjects();
    this.loadModules();
  },
  methods: {
    //Load all modules from student
    loadSubjects() {
      if (sessionStorage.subjects) {
        this.subjects = JSON.parse(window.sessionStorage.getItem("subjects"));
      } else {
        this.isLoadingSubjects = true;
        subjectAndModuleRepository
          .getSubjects()
          .then(({ data }) => {
            sessionStorage.setItem("subjects", JSON.stringify(data));
            this.subjects = JSON.parse(
              window.sessionStorage.getItem("subjects")
            );
          })
          .catch((err) => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar obtener los datos",
            });
          })
          .finally(() => {
            this.isLoadingSubjects = false;
          });
      }
    },

    //Load all modules from student
    loadModules() {
      if (sessionStorage.modules) {
        this.modules = JSON.parse(window.sessionStorage.getItem("modules"));
      } else {
        this.isLoadingModules = true;
        subjectAndModuleRepository
          .getModules()
          .then(({ data }) => {
            sessionStorage.setItem("modules", JSON.stringify(data));
            this.modules = JSON.parse(window.sessionStorage.getItem("modules"));
          })
          .catch((err) => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar obtener los datos",
            });
          })
          .finally(() => {
            this.isLoadingModules = false;
          });
      }
    },

    //Depending on the subject/module clicked, uses it's id to load evaluations
    goToSubjectEvaluations(subject) {
      this.$router.push({
        path: "student_scores",
        query: {
          s: subject.subject_teacher_id,
        },
      });
      this.$store.dispatch(SET_SUBJECT, subject);
    },

    //Module state setter functions
    moduleStateIconSetter(stateID) {
      if (stateID == 0) {
        return "play-circle-outline";
      } else if (stateID == 1) {
        return "pause-circle-outline";
      } else {
        return "check-circle";
      }
    },
    moduleStateColorSetter(stateID) {
      if (stateID == 0) {
        return "blue";
      } else if (stateID == 1) {
        return "orange";
      } else {
        return "success";
      }
    },
    moduleStateTextSetter(stateID) {
      if (stateID == 0) {
        return "Módulo no iniciado";
      } else if (stateID == 1) {
        return "Módulo en progreso";
      } else {
        return "Módulo finalizado";
      }
    },
    async setFormCompletionState() {
      const result = await this.setFormCompletionStatus();
      this.isFormCompleted = !result;
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    studentCode() {
      return this.currentUserPersonalInfo.code;
    },
  },
};
</script>

<style>
.box {
  aspect-ratio: 1/1;
}

.card-icon-container {
  aspect-ratio: 1/1;
  height: 100%;
  max-height: 95px;
  max-width: 95px;
}

.module-state-indicator {
  position: absolute;
  bottom: 13px;
}

.subject-card {
  min-height: 180px;
}

@media (max-width: 960px) {
  .module-state-indicator {
    top: 13px;
  }
}

@media (max-width: 350px) {
  .action-buttons-container {
    flex-direction: column-reverse !important;
  }
  .update-btn {
    margin-bottom: 1em !important;
  }
  .fallback-alert {
    display: none !important;
  }
}
</style>
